<template>
  <div>
    <div class="header">
      <div class="header-container" v-if="isLogin">
        <img src="../assets/img/logintitle.jpg" class="header-img" />
        <p class="header-text" @click="toLogin">{{username}}</p>
      </div>
      <div class="header-container" v-else>
        <img src="../assets/img/logintitle.jpg" class="header-img" />
        <p class="header-text" @click="toLogin">点击登陆</p>
      </div>
    </div>
    <ul class="mine-mian-ul">
      <li class="mine-mian-ul-li" type="primary" @click="tokeFu">
        <span>客服中心</span>
        <van-icon name="arrow" class="mine-mian-ul-li-van" />
      </li>
      <li :class="isLogin ?'mine-mian-ul-li':'mine-mian-ul-li exitborder'" @click="toAbout">
        <span>联系我们</span>
        <van-icon name="arrow" class="mine-mian-ul-li-van" />
      </li>
      <li class="mine-mian-ul-li exitborder" @click="tologOut" v-if="isLogin">
        <span>退出登录</span>
        <van-icon name="arrow" class="mine-mian-ul-li-van" />
      </li>
    </ul>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      show: false,
      isLogin: false,
      username: ""
    };
  },
  created() {
    let token = window.sessionStorage.getItem("Token");
    this.username = window.sessionStorage.getItem("username");
    if (token) {
      this.isLogin = true;
    }
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
    tokeFu() {
      window.location.href =
        "http://p.qiao.baidu.com/cps/chat?siteId=12623578&userId=26512539&cp=lianwen&cr=lianwen&cw=Mobile";
    },
    toAbout() {
      this.$router.push("/about");
    },
    tologOut() {
      Dialog.confirm({
        title: "标题",
        message: "确定要退出吗"
      }).then(() => {
        window.sessionStorage.clear();
        this.isLogin = false;
      });
    }
  }
};
</script>

<style scoped>
.header {
  height: 3.3rem;
  width: 100%;
  background-color: #228ef7;
}
.header-img {
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: 50%;
  margin-top: 0.8rem;
  left: 5%;
}
.header-container {
  position: relative;
}
.header-text {
  position: absolute;
  top: 50%;
  margin-top: 1.4rem;
  margin-left: 2.6rem;
  color: #fff;
  font-weight: 10px;
  font-size: 0.4rem;
}
.mine-mian-ul {
  background-color: white;
  margin-top: 0.3rem;
}
.mine-mian-ul-li {
  height: 0.9rem;
  margin-left: 0.3rem;
  margin-right: 0.24rem;
  border-bottom: 0.01rem solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.3rem;
}
.mine-mian-ul-li span {
  font-size: 0.3rem;
  padding-left: 0.2rem;
}
.mine-mian-ul-li-van {
  color: #b3aca8;
  height: 0.9rem;
  line-height: 0.9rem;
}
.exitborder {
  border-bottom: 0;
}
</style>
